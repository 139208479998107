<template>
    <div class="lvya-join">
        <div class="join-img1"><img src="static/lvya-old/qie_01.png" alt=""></div>
        <div class="join-img2"><img src="static/lvya-old/qie_03.png" width="999"></div>
        <img src="static/lvya-old/qie_04.png" alt="" style="width:100%;">
        <img src="static/lvya-old/qie_05.png" alt="" style="width:100%;">
        <div class="join-div">
            <img src="static/lvya-old/join1.png" alt="">
            <div class="join-div-box">
                <div class="join-div-left">
                  <img src="static/lvya-old/join.png" alt="" style="width:300px;">
                </div>
                <div class="join-div-right">
                    <el-form :model="form" ref="form" :rules="rules" label-width="80px">
                        <el-form-item label="" prop="name" :required="true">
                            <img src="static/lvya-old/join3.png" alt="">
                            <el-input v-model="form.name" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="phone" :required="true">
                            <img src="static/lvya-old/join2.png" alt="">
                            <el-input v-model.number="form.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="email" :required="true">
                            <img src="static/lvya-old/join4.png" alt="">
                            <el-input v-model="form.email" placeholder="邮箱"></el-input>
                        </el-form-item>
                        <el-form-item label="">
                            <img src="static/lvya-old/join5.png" alt="">
                            <el-input v-model="form.bz" placeholder="备注"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('form')" style="background-color:#05b21a;border:none;">立即获取</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
export default {
  components: {
  },
  data() {
      const checkName = (rule, value, callback) => {
          if (!value) {
              callback(new Error('内容不能为空'))
          }
      }
      const checkPhone = (rule, value, callback) => {
          if (!value) {
              callback(new Error('手机号不能为空'))
          } else {
              const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
              if (!reg.test(value)) {
                  callback(new Error('请输入正确的手机号'))
              }
              callback()
          }
      }
      const checkEmail = (rule, value, callback) => {
          const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
          if (!value) {
              return callback(new Error('邮箱不能为空'))
          }
          setTimeout(() => {
              if (mailReg.test(value)) {
                  callback()
              } else {
                  callback(new Error('请输入正确的邮箱格式'))
              }
          }, 100)
      }
      return {
          xx: '',
          form: {
              name: '',
              phone: '',
              email: '',
              bz: '',
          },
          rules: {
              name: [
                  { validator: checkName, trigger: 'blur' }
              ],
              phone: [
                  { validator: checkPhone, trigger: 'blur' }
              ],
              email: [
                  { validator: checkEmail, trigger: 'blur' }
              ],
          }
      }
  },
  methods: {
    // http://www.lvya.org/join/add
      getInformation() {
          axios.post('http://www.lvya.org/join/add', qs.stringify({
              phone: this.form.phone,
              email: this.form.email,
              name: this.form.name,
              bz: this.form.bz
          }))
          .then(res => {
              this.xx = res.data.errorDesc
              if (this.xx == '成功') {
                  this.$message({
                  message: '您的申请我们已经收到，我们将尽快联系您！！',
                  type: 'success'
              })
                  // alert('您的申请我们已经收到，我们将尽快联系您！')
              } else {
                  this.$message.error(this.xx)
                  // alert(this.xx)
              }
              console.log(res.data.errorDesc)
          })
      },
      submitForm(formName) {
          console.log(this.$refs[formName] + '===1111')
          this.getInformation()
          // this.$refs[formName].validate((valid) => {
          //     if (valid) {
          //         this.getInformation()
          //         alert('您的申请我们已经收到，我们将尽快联系您！')
          //     } else {
          //         console.log('error submit!!')
          //         return false
          //     }
          // })
      },
  },
  created() {
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.lvya-join{
  width: 100%;
  min-width: 1200px;
  .join-img1{
    width: 100%;
    line-height: 0;
    img{
      width: 100%;
      min-width: 1200px;
    }
  }
  .join-img2{
    width: 100%;
    height: 500px;
    background: #fff url(/static/lvya-old/qie_02.png) repeat scroll center top;
    text-align: center;
    padding: 50px 0;
  }
  .join-div{
    width: 1000px;
    min-height: 200px;
    margin: 50px auto;
    text-align: center;
  }
  .join-div-box{
      width: 710px;
      display: flex;
      margin: 0 auto;
      margin-top: 30px;
  }
  .join-div-left{
      width: 360px;
      border-right: 1px solid #ddd;
  }
  .join-div-right{
      width: 300px;
      margin-left: 50px;
  }
  .join-div-right .el-form{
      margin-top: 60px;
  }
  /deep/.join-div-right .el-form-item__content{
      margin-left: 0!important;
      position: relative;
  }
  .join-div-right .el-form-item__content img{
      position: absolute;
      left: 10px;
      top: 9px;
      z-index: 99;
  }
  /deep/ .join-div-right .el-form-item__content .el-input input{
      padding-left: 40px;
  }
  /deep/ .join-div-right .el-input__inner:focus{
      border-color: #05b21a;
  }
}
</style>
